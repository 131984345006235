import React, { Fragment } from "react"

import Sub from "~layout/sub"
import aside from "~content/products/aside"

import { HeadingIcon } from "~styled"

const crumbs = [
  { text: "Products", to: "/products" },
  { text: "Swiiit", to: "/products/swiiit/" },
]

const SwiiitPage = () => {
  let content = (
    <Fragment>
      <HeadingIcon>
        <img src={require("~images/products/swiiit.png").default} alt="Swiiit" title="Swiiit" />
      </HeadingIcon>
      <h1>Creating a website has never been easier.</h1>
      <p>
      The ultimate tool to simplify website creation and management. Swiiit is designed for both individuals and medium-to-large enterprises, providing an all-in-one solution to create and personalize websites with ease.
      </p>

<h5>Unleash Your Creativity: Choose from Over 70 Templates</h5>
      <p>
      With Swiiit, you can unleash your creativity by choosing from a wide range of more than 70 templates. Make your website stand out by customizing everything from layout to photos and fonts. No coding knowledge is necessary! You can create stunning content just by pointing and typing, and effortlessly insert images and web applications with a simple drag and drop.
      </p>

      <h5>Scalable Solution: Perfect for Medium and Large Enterprises</h5>
      <p>
      Swiiit is more than just a website builder. It's a scalable solution that enables medium and large enterprises to create microsites quickly, making it perfect for businesses with multiple teams, products, and services. Access Control is a powerful feature that allows you to assign editing rights not just to different people but also to different sections of your website, ensuring security and consistency throughout your online presence.
      </p>

<br/>
      <p><strong>Access Control: Grant team members edit rights to microsites and pages</strong></p>
      <p>
      <img src={require("~images/products/SWT/Features/Access Control.png").default} alt="Swiiit - Access Control" title="Swiiit - Access Control" className="margin-auto img-responsive" />
      </p>

      <h5>Intuitive Design: All-in-One Toolbar for Hassle-Free Website Building</h5>
      <p>
      Experience the ultimate website building and editing solution with Swiiit - the all-in-one Toolbar that simplifies the website creation process. Building a website has never been easier with Swiiit's intuitive design and user-friendly interface. Swiiit's all-in-one Toolbar makes the website building process seamless and hassle-free. With a few simple clicks, you can publish your website and share it with the world.
      </p>
      <p>
      <img src={require("~images/products/SWT/Features/All-in-one Toolbar for Easy Editing.jpg").default} alt="Swiiit - All-in-one Toolbar for Easy Editing" title="Swiiit - All-in-one Toolbar for Easy Editing" className="margin-auto img-responsive" />
      </p>

      <h5>Flexibility in Creating Stunning and Expressive Websites</h5>
      <p>
      Swiiit offers unparalleled flexibility in creating stunning and expressive websites. Choose from a wide range of designs and templates to create a unique online presence that matches your brand's identity. The point-and-type feature allows you to create and edit content with ease, while the drag-and-drop function enables you to insert images and web applications effortlessly.
      </p>

      <h5>Ideal Solution for Businesses of Any Size</h5>
      <p>
      With Swiiit, you can easily develop a website that meets your business needs and showcases your products or services in the best light. Swiiit is the perfect solution for everyone, from individuals to medium-to-large enterprises. Its versatility and scalability make it ideal for businesses of any size.
      </p>

      <h5>Save Time and Focus on High-Quality Content</h5>
      <p>
      With Swiiit, you can save time and focus on what matters most - creating high-quality content that attracts and retains your audience. Try Swiiit today and see how easy it is to create a website that stands out from the crowd!
      </p>

      <h5>Performance</h5>
      <p className="inline-elements">
      <img src={require("~images/products/SWT/Features/Performance.gif").default} alt="Swiiit - Performance" title="Swiiit - Performance" className="margin-auto img-responsive inline-block" />
      <img src={require("~images/products/SWT/Features/Performance2.gif").default} alt="Swiiit - Performance" title="Swiiit - Performance" className="margin-auto img-responsive  inline-block" />
      </p>

      {/* <p>
        <img
          src={require("~images/products/swiiit1.png").default}
          alt="Swiiit 1"
          className="img-responsive margin-auto block"
        />
      </p>

      <p>
        Swiiit is the selected content management system provider to Singapore government agencies and Ministry of Education schools under the <i>Info-communications Media Development Authority (IMDA) (T)1374 Content Websites Platform (CWP) tender</i>. More than 270 schools in Singapore use SWIIT for their websites because of its intuitive features and ease of use.
      </p>

      <p>
        Interested in a CWP partnership with us? Contact us at <a href="mailto:partnership@commontown.com" alt="" >partnership@commontown.com</a> today.
      </p>


      <p>
        For sales enquiries, drop us an email
        at <a href="mailto:biz@commontown.com" alt="">biz@commontown.com</a> or call us at <a href="tel:6848 8900" alt="" >6848 8900</a>.</p>
      <p>
        For more information, visit <button type="button" className="type-link" onClick={() => window.open('https://www.swiiit.com')} onKeyDown={() => window.open('https://www.swiiit.com')}  >www.swiiit.com</button>.
      </p> */}
      <br/>
      <p className="text-center">
      <a href="https://www.swiiit.com" title="Visit SWIIIT"><img src={require("~images/products/swiiit-logo.png").default} alt="SWIIIT" title="SWIIIT" className="margin-auto img-responsive block" />
        Visit SWIIIT</a>
      </p>
     <h5 className="contactus-info">Contact Us</h5>
      <p>For more information or sales enquiries, email us at <a href="mailto:biz@commontown.com" alt="" >biz@commontown.com</a> or call us at <a href="tel:6848-8900" alt="">6848-8900</a>.</p>
    </Fragment>
  )

  return <Sub title="Swiiit" crumbs={crumbs} content={content} aside={aside} />
}

export default SwiiitPage
